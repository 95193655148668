import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"x-cache":"HIT, HIT","x-jsd-version":"1.0.8","cross-origin-resource-policy":"cross-origin","cf-ray":"907f59944d2113ad-CMH","vary":"Accept-Encoding","cf-cache-status":"HIT","age":"7069","alt-svc":"h3=\":443\"; ma=86400","x-jsd-version-type":"version","accept-ranges":"bytes","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=PKI14hHEBbgA%2FNoJPXMzcECUxd7GZDcdsuOlZoK0gf3ez%2F0%2FXBeBay7%2B6zroxpaT4zLtVUY8q2e8L64%2BgYqyD4fUCtVl9r1Rt5aJbrADlsXrXL6Uz2rq2kIMd7gILrcLt1U%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","timing-allow-origin":"*","strict-transport-security":"max-age=31536000; includeSubDomains; preload","date":"Sun, 26 Jan 2025 09:06:57 GMT","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","server":"cloudflare","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21962-LGA","access-control-expose-headers":"*","access-control-allow-origin":"*","content-type":"text/plain; charset=utf-8","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","x-content-type-options":"nosniff","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1737882417}